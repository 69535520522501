import React from "react"
import { useCourseStore } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import Box from "../../../../../components/box"
import CourseFooterBool from "../../../../../components/course-footer-bool"
import Paragraph from "../../../../../components/paragraph"
import { getCourseNavigation } from "../../../../../store/courses"

const Page = () => {
  const { setAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "versteckte-ordnung" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterBool
          title="Ist das ein Gedicht?"
          to="/kurse/versteckte-ordnung/01-gedicht-erkennen/fruehjahrsputz/loesung"
          onSubmit={(submission) => {
            setAnswer({
              courseId: "versteckte-ordnung",
              chapterId: "01-gedicht-erkennen",
              taskId: "fruehjahrsputz",
              answer: submission,
            })
          }}
        />
      }
      navigation={navigation}
    >
      <Seo title="Frühjahrsputz" />
      <Box>
        <Stack>
          <Heading as="h1" level={2}>
            Frühjahrsputz
          </Heading>
          <Paragraph>
            Der Maulwurf hatte schon den ganzen Morgen schwer geschuftet, der
            Frühjahrsputz in seinem kleinen Zuhause stand an. Zuerst ging er mit
            Besen, dann mit Staubwedeln zu Werke, dann auf Leiter und Tritten
            und Stühlen mit einem Pinsel und einem Eimer weißer Farbe, bis er
            Staub in Kehle und Augen und Farbspritzer überall auf seinem
            schwarzen Pelz hatte... Der Frühling erfüllte die Luft über ihm, die
            Erde unter ihm und alles um ihn herum und durchdrang sogar sein
            dunkles, bescheidenes, kleines Heim mit dem Geist himmlischer Unruhe
            und Sehnsucht.
          </Paragraph>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
